<template>
  <div class="new-job">
      <div class="new-job-wrapper">
          <div class="new-job-field">
              <p>כותרת מודעה</p>
              <el-input v-model="values.job_header" placeholder="כותרת מודעה" />
          </div>
          <div class="new-job-field">
            <p>חברה</p>
            <el-select style="width:100%;" v-model="values.job_company" placeholder="חברה">
                <el-option
                v-for="company in companies_options"
                :key="company"
                :value="company"
                >
                </el-option>
            </el-select>
          </div>
          <div class="new-job-field">
              <p>תפקיד</p>
              <el-input v-model="values.role" placeholder="תפקיד" />
          </div>
          <div class="new-job-field">
            <p>פירוט המשרה</p>
            <el-input
                class="job-detail"
                v-model="values.job_details"
                :rows="5"
                type="textarea"
                placeholder="פירוט המשרה..."
            />
          </div>
          <template v-for="(requirement,index) in values.requirements" :key="index">
            <div class="new-job-field">
              <p>דרישת חובה 
                <span class="add-btn" @click="handle_add_requirement" v-if="index==values.requirements.length-1">
                  <i class="material-icons">
                    add
                  </i>
                </span>
                <span class="remove-btn" @click="handle_remove_requirement(index)" v-if="index==values.requirements.length-1 && index!=0">
                  <i class="material-icons">
                    remove
                  </i>
                </span>
              </p>
              <el-input v-model="values.requirements[index].requirement" placeholder="דרישת חובה" />
            </div>
          </template>
          <div class="upload-image">
              <div v-loading="uploading_pending" @click="handle_upload_image" v-if="!values.image.url" class="image-file">
                  <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Ffolder%20(1)_375x375.png?alt=media&token=ceacfbdd-02e4-4145-aac0-6eb13886e0c6">
              </div>
              <div v-if="values.image.url" class="image">
                  <img :src="values.image.url">
                  <div @click="handle_remove_image" class="delete-btn">
                      <i class="material-icons">
                        delete
                      </i>
                  </div>
              </div>
          </div>
          <div class="new-job-field">
              <el-button v-if="!edit" @click="handle_submit" style="width:100%;" type="success">סיום</el-button>
              <el-button v-if="edit" @click="handle_update_edit" style="width:100%;" type="success">עדכון</el-button>
          </div>
      </div>
       <input v-show="false" id="upload-image" type="file" @change="handleChange"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import use_jobs_storage from '../../../../Methods/use_jobs_storage'
import {slide_pop_error,alert} from '../../../../Methods/Msgs'
import {projectFirestore} from '../../../../firebase/config'
import { onMounted } from '@vue/runtime-core'
export default {
    props:['edit'],
    emits:['close'],
    setup(props,{emit}){
        const error_msg = ref('')
        const {url,filePath,uploadImage,deleteImage}  = use_jobs_storage()
        const file = ref(null)
        const uploading_pending = ref(false)
        const companies_options = ref([
            'לוגיסטיקה',
            'שיווק',
            'דיוטי פארם',
            'אחר'
        ])
        const values = ref({
            createdAt:new Date(),
            job_header:'',
            job_company:'',
            role:'',
            job_details:'',
            requirements:[
                {
                    requirement:''
                },
            ],
            image:{
                file_path:'',
                url:''
            },
            applicators:[],
            status:'new'
        })
        const handle_add_requirement = ()=>{
            values.value.requirements.push({
                requirement:''
            })
        }
        const handle_remove_requirement = (index)=>{
            values.value.requirements.splice(index,1)
        }
        const handleChange=async(e)=>{
            const types = ["image/png", "image/jpeg", "image/jpg"];
             const selected = e.target.files[0];
             if (selected && types.includes(selected.type)) {
                file.value = selected;
                uploading_pending.value=true
                await uploadImage(file.value,remove_slashes(values.value.job_header));
                values.value.image.url = url.value
                values.value.image.file_path = filePath.value
                uploading_pending.value=false
             }else{
                file.value = null;
                slide_pop_error('סוג קובץ זה אינו נתמך')
             }
        }
        const handle_upload_image = ()=>{
            document.getElementById('upload-image').click()
        }
        const handle_remove_image = async()=>{
            await deleteImage(values.value.image.file_path)
            values.value.image.url=""
            values.value.image.file_path=""
        }

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'job_header':
                        if(!value){
                            error_msg.value = 'עליך להזין כותרת למודעה'
                            return false
                        }
                        break;
                    case 'job_company':
                        if(!value){
                            error_msg.value = 'עליך להזין שם חברה'
                            return false
                        }
                        break;
                    case 'role':
                        if(!value){
                            error_msg.value = 'עליך להזין תפקיד'
                            return false
                        }
                        break;
                    case 'job_details':
                        if(!value){
                            error_msg.value = 'עליך להזין פירוט למשרה'
                            return false
                        }
                        break;
                    case 'requirements':
                        for(const req of value){
                            if(!req.requirement){
                                error_msg.value = 'עליך להזין דרישות חובה'
                                return false
                            }
                        }
                        break;
                    case 'image':
                        if(!value.url){
                            error_msg.value = 'עליך להעלות תמונה'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                await store_data_in_db()
                alert('success','הצלחה','הטופס נקלט בהצלחה')
                .then(()=>{
                    reset()
                })
            }
        }

        const handle_update_edit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                await projectFirestore.collection('Jobs').doc(props.edit)
                .set(values.value,{merge:true})
                alert('success','הצלחה','המודעה עודכנה בהצלחה')
                .then(()=>{
                    emit('close')
                })

            }

        }

        const reset = ()=>{
            values.value = {
                createdAt:new Date(),
                job_header:'',
                job_company:'',
                role:'',
                job_details:'',
                requirements:[
                    {
                        requirement:''
                    },
                ],
                image:{
                    file_path:'',
                    url:''
                },
                applicators:[],
                status:'new'
            }
        }

        const store_data_in_db = async()=>{
            const doc = projectFirestore.collection('Jobs').doc()
            await doc.set({
                ...values.value,
                id:doc.id
            })
        }

        function remove_slashes(str) {
            return str.split('/').join('')
        }

        onMounted(async()=>{
            if(props.edit){
                await get_data_from_db_if_edit()
            }
        })

        const get_data_from_db_if_edit = async()=>{
            const doc = await projectFirestore.collection('Jobs').doc(props.edit).get()
            values.value = doc.data()
        }

        return{
            handle_remove_image,
            handle_add_requirement,
            handle_remove_requirement,
            handle_submit,
            handle_upload_image,
            handle_update_edit,
            handleChange,
            file,uploading_pending,
            values,
            companies_options,
            error_msg,
            }
    }
}
</script>

<style scoped>
    .new-job{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow:hidden;
        overflow-y: auto;
        color: #fff;
    }
    .new-job-wrapper{
        width: 70%;
        height: fit-content;
    }
    .new-job-field{
        width: 100%;
        height: fit-content;
        font-size: 25px;
        margin-top: 10px;
    }
    .job-detail{
        white-space: pre-wrap;
    }
    .add-btn{
        cursor: pointer;
        color: green;
        font-size: 25px;
        font-weight: bold;
    }
    .remove-btn{
        cursor: pointer;
        color: red;
        font-size: 25px;
        font-weight: bold;
    }
    .upload-image{
        margin-top: 10px;
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .upload-image .image-file{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .upload-image .image{
        position: relative;
        width: 50%;
        height: 100%;
    }
    .upload-image .image .delete-btn{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: crimson;
        border-radius: 5px;
        cursor: pointer;
    }
    .upload-image .image img{
        max-width: 100%;
        max-height: 100%;
    }
</style>